<template>
  <div class="abilityDetails">
    <div class="content">
      <!-- 时间 -->
      <div class="time top">
        <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)"/>
        <div class="current">{{ current }}</div>
        <svg-icon v-show="current != today" icon-class="back" class="arr arr-right"  @click="getDate(true)"/>
      </div>
      <!-- 计时 -->
      <div class="timing">
        <div class="num" style="text-align: center;">{{currentTime}}&nbsp;平均</div>
        <div class="number">{{ currentPressure }}<span class="high"></span></div>
      </div>
      <!-- 放置图表 -->
      <div class="charts">
        <div id="main" class="pressurecharts"></div>
      </div>
      <!-- tips -->
      <div class="Tips">
        <div class="heigh">偏高80-90</div>
        <div class="medium">中等60-79</div>
        <div class="normal">正常30-59</div>
        <div class="relax">放松1-29</div>
      </div>
      <!-- button 最下面两个按钮 -->
      <div class="buttons">
        <div class="btn">
          <div class="average">当日平均值</div>
          <div class="num">{{ pressureAvg }}<span class="mercury">正常</span></div>
        </div>
        <div class="btn btn1">
          <div class="pressure">压力范围</div>
          <div class="num">{{pressureMin}}-{{ pressureMax }}</div>
        </div>
      </div>
    </div>
    <div class="content history-box">
        <!-- 时间 -->
        <div class="time">
            <!-- <svg-icon icon-class="back" /> -->
            <div class="current">压力走势</div>
        </div>
        <!-- 折线图 -->
        <div class="charts">
            <div id="historyBrokenLine" class="pressurecharts"></div>
        </div>
        <HistoryBar @onIndexCahnge="onIndexCahnge"></HistoryBar>
        <!-- 提示 -->
        <div class="buttons">
        <div class="btn">
          <div class="average">当日平均值</div>
          <div class="num">{{ historyPressureAvg }}<span class="mercury">正常</span></div>
        </div>
        <div class="btn btn1">
          <div class="pressure">压力范围</div>
          <div class="num">{{historyPressureMin}}-{{ historyPressureMax }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { current } from '@/util/validate'
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart ,LineChart} from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import { GridComponent, TooltipComponent } from 'echarts/components'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { mapGetters } from 'vuex'
import HistoryBar from '../../components/tabbar/HistoryBar.vue'

// 注册必须的组件
echarts.use([GridComponent, BarChart, CanvasRenderer, LineChart, TooltipComponent])
export default {
  name: 'Pressure',
  computed: {
    ...mapGetters([
      'userid'
    ])
  },
  components: {
    HistoryBar
  },
  data () {
    return {
      current: current(),
      today: current(),
      currentTime: '',
      currentPressure: 0,
      xData: [],
      YData: [],
      pressureAvg: null,
      pressureMax: 0,
      pressureMin: 0,
      currentActiveIndex:0,
      historyPressureAvg:0,
      historyPressureMax:0,
      historyPressureMin:0,
      historyXData:[],
      historyYData:[],
    }
  },
  mounted () {
    this.getPressureData()
    this.getHistoryPressureData()
  },
  methods: {
    getPressureData(){
      GetWatchTypeByDate({
        userId: this.userid,
        time: this.current,
        type: 5// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        this.pressureAvg = _data.valueAvg
        this.pressureMax = _data.max
        this.pressureMin = _data.min
        this.xData = _data.timelist[0].time
        this.yData = _data.timelist[0].value
        this.$nextTick(() => {
          this.pressureConfig()
        })
      }).catch(err=>{
        this.xData = []
        this.yData = []
        this.$nextTick(() => {
          this.pressureConfig()
        })
      })
    },
    // 压力柱状图
    pressureConfig () {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
            trigger: 'axis',
            formatter:  (value)=> {
              let xIndex = value[0].dataIndex
              this.currentPressure = this.yData[xIndex]
              this.currentTime = `${this.xData[xIndex]}-${this.xData[xIndex + 1]}`
            }
        },
        grid: {
          left: '0',
          right: '7%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          type: 'category',
          data: this.xData,
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            interval: Math.ceil(this.xData.length / 4),
            margin:20
          }
        },
        yAxis: {
          type: 'value',
          max: 100,
          min: 0,
          interval: 20,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              // y轴线的样式
              type: 'dashed'
              // color:'#969798',
            }
          }
        },
        series: [
          {
            data: this.yData,
            type: 'bar',
            // barWidth: 4,
            itemStyle: {
              color: (arg) => {
                if (arg.value < 30) {
                  return '#1496B8'
                } else if (arg.value >= 30 && arg.value < 59) {
                  return '#7DD1DF'
                } else if (arg.value >= 59 && arg.value < 79) {
                  return '#EDDD80'
                } else if (arg.value >= 79 && arg.value < 100) {
                  return '#DB7D2B'
                }
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
      // myChart.getZr().on('click', (params) => {
      //   const pointInPixel = [params.offsetX, params.offsetY]
      //   if (myChart.containPixel('grid', pointInPixel)) {
      //     const xIndex = myChart.convertFromPixel({ seriesIndex: 0 }, pointInPixel)[0]
      //     // console.log('你在点击第', xIndex, '个格子')
      //     this.currentPressure = this.yData[xIndex]
      //     this.currentTime = `${this.xData[xIndex]}-${this.xData[xIndex + 1]}`
      //   }
      // })
    },
    //历史数据
    getHistoryPressureData(){
      GetWatchTypeByHistoryDate({
        userId: this.userid,
        timeType: this.currentActiveIndex+1,//1近一周 2近一个月 3近三个月 4近半年 5近一年
        dateTtpe: 5// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
      }).then(res => {
        const _data = res.data
        // console.log('------',_data)
        let _list = _data.daysList.reverse()
        this.historyXData = _list.map(item=>{
            return item.time;
        })
        this.historyYData = _list.map(item=>{
            return item.value;
        })
        this.historyPressureAvg= _data.pressureAvg
        this.historyPressureMax = _data.pressureMax
        this.historyPressureMin = _data.pressureMin
        this.$nextTick(() => {
            this.initHistoryBrokenLine()
        })
      })
    },
    initHistoryBrokenLine(){
      if (!document.getElementById('historyBrokenLine')) {
        return
      }
      let chartDom = document.getElementById('historyBrokenLine')
      let myChart = echarts.init(chartDom)
      let option
      option = {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: this.historyXData,
        //   nameLocation: "end",
          boundaryGap: false,
          axisLine: {
            show: false // 是否显示坐标轴
          },
          axisTick: {
            show: false // 是否显示坐标轴刻度
          },
          splitLine: { show: false },// 去除网格线
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            margin:'20',
            formatter:(v, i)=> {
                return this.currentActiveIndex == 0 || this.currentActiveIndex == 1? v.substring(5):v
            },
          }
        },
        yAxis: {
          type: 'value',
          scale: true,
          // max : 100,
          min: 0,
          splitNumber: 3,
        },
        grid: {
          left: "10%",
          right: "7%",
          bottom:"20%"
        },
        series: [
          {
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#FF9281'
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF9180'
                },
                {
                  offset: 1,
                  color: '#FFC9C0'
                }
              ])
            },
            data: this.historyYData
          }
        ]
      }
      option && myChart.setOption(option)
    },
    onIndexCahnge(index){
      this.currentActiveIndex = index
      this.getHistoryPressureData()
    },
    getDate(flag){
      let t = flag? new Date(this.current).getTime()+86400000: new Date(this.current).getTime()-86400000
      this.current = current(new Date(t))
      this.getPressureData()
    },
    
  }
}
</script>

<style lang="scss" scoped>
.abilityDetails {
  height: 100%;
  padding: 0 20px;
  background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag6.png') no-repeat;
  background-size: 100%;
  background-color: #f7f7f7;
  .content {
    width: 100%;
    border-radius: 15px;
    background: #fff;
    // height: 80%;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    .time {
            color: #868686;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: relative;
            .arr-left{
              position: absolute;
              top:50%;
              transform: translate(0, -50%);
              left:-10px;
            }
            .arr-right{
              position: absolute;
              top:50%;
              transform: translate(0, -50%) rotate(180deg);
              right:-10px;
            }
            &.top{
              margin-bottom: 20px;
              display: flex;
              align-content: center;
            }
            .current {
                font-size: 20px;
                font-weight: 700;
                margin: 0 10px;
            }
        }
    .timing {
      color: #858585;
      font-size: 25px;
      margin-top: 20px;
      .number {
        text-align: center;
        color: #4f4f4f;
        margin-top: 20px;
        font-size: 35px;
      }
      .high {
        font-size: 23px;
        color: #858585;
      }
    }
    .charts {
      width:100%;
      position: relative;
      .pressurecharts {
        width: 100%;
        height: 240px;
      }
      .timenode {
          width: 90%;
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 0;
          right: 0;
      }
    }
    .Tips {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .heigh,
      .medium,
      .normal,
      .relax {
        position: relative;
        color: #666666;
        font-size: 13px;
      }
      .heigh::before,
      .medium::before,
      .normal::before,
      .relax::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
        margin-left: 2px;
      }
      .heigh::before {
        background: #db7d2b;
      }
      .medium::before {
        background: #eddd80;
      }
      .normal::before {
        background: #7dd1df;
      }
      .relax::before {
        background: #1496b8;
      }
    }
    .buttons {
      display: flex;
      margin-top: 30px;
      .btn {
        width: 155px;
        height: 65px;
        border: 1px solid #fceecc;
        background: #fef8ea;
        border-radius: 8px;
        .average,
        .pressure {
          position: relative;
          font-size: 5px;
          color: #858585;
          padding: 6px 20px;
        }
        .average::before,
        .pressure::before {
          content: "";
          width: 5px;
          height: 11px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 5px;
        }
        .average::before {
          background: #f6bd34;
        }
        .pressure::before {
          background: #db7d2b;
        }
        .num {
          font-size: 20px;
          margin: 5px 5px 5px 60px;
        }
      }
      .btn1 {
        margin-left: 5px;
        background-color: #fff4f2;
        border: 1px solid #ffd6d0;
      }
      .mercury {
        font-size: 10px;
        color: #858585;
      }
    }
  }
}
</style>
